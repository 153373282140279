:root {
  --primary-bg-color: "#ffffff";
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 2050px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .swal2-popup { */
/* font-size: 1.6rem !important; */
/* font-family:Verdana, Geneva, Tahoma, sans-serif; */
/* } */

.swal2-title {
  font-family: "Montserrat", sans-serif;
}
