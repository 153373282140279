.container-spinner {
    /* height: 300px; */
    display: flex;
    margin: auto;
}
.logo-spinner svg {
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-name: spinner;
}

@keyframes spinner {
    0% {
        transform: rotate(-360deg);
    }
}
