.move {
  animation-iteration-count: initial;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-name: move;
}

.scale {
  transition: 0.25s;
}
.scale:hover {
  transform: scale(0.97);
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(10px, 0px);
  }
  20% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: translate(10px, 0px);
  }
  40% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(10px, 0px);
  }
  60% {
    transform: translate(0px, 0px);
  }
  70% {
    transform: translate(10px, 0px);
  }
  80% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}


